<!-- 底部导航栏 -->
<template>
  <div id="FooterMenu">
    <ul>
      <li :class="{ 'active': item.active }" v-for="(item, index) in menuList" :key="index" @click="switchTab(item)">
        <span class="iconfont icon-zhuye-xuanzhong" :class="item.icon"></span>
        <p>{{ item.name }}</p>
      </li>
      <!-- <li class="">
        <span class="iconfont icon-chongzhijiaofei"></span>
        <p>充值</p>
      </li>
      <li class="">
        <span class="iconfont icon-trend-fill"></span>
        <p>走势</p>
      </li>
      <li class="">
        <span class="iconfont icon-wode-wode"></span>
        <p>我的</p>
      </li> -->
    </ul>
  </div>
</template>

<script>
export default {
  name: "FooterMenu",
  props: {
    activeIndex: {
      type: String,
      default: '-1'
    }
  },
  data() {
    return {
      menuList: [
        {
          name: this.$t("m_tabbar.home"),
          icon: "icon-zhuye-xuanzhong",
          path: "/index",
          active: false
        },
        {
          name: this.$t("m_tabbar.deposit"),
          icon: "icon-chongzhijiaofei",
          path: "/withdraw",
          active: false
        },
        {
          name: this.$t('m_home.czck'),
          icon: "icon-trend-fill",
          path: "/recharge",
          active: false
        },
        {
          name: this.$t("m_tabbar.mime"),
          icon: "icon-yonghutianchong",
          path: "/user",
          active: false
        },
      ],
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {
    console.log(this.activeIndex)
    console.log('111111111111111111111')
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    console.log(this.activeIndex)
    console.log('22222222222222222222222')
    this.menuList[this.activeIndex].active = true;
    console.log(this.menuList)
  },
  methods: {
    switchTab(item) {
      this.menuList.forEach((row) => {
        row.active = false;
      })
      item.active = true;
      this.$router.push(item.path);
      console.log("点击了");
    },
  },
};
</script>
<style lang="less" scoped>
#FooterMenu {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 1.3rem;

  .active {
    p {
      color: #007aff!important;
    }

    .iconfont {
      color: #007aff!important;
    }
  }

  ul {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 750px;
    /* background: linear-gradient(0deg, #ceab77 0%, #eddbc1 100%); */
    background: #0C366D;




    li {
      flex: 1;
      text-align: center;
      margin-top: -10px;

      p {
        color: #ffffff;
        font-size: 0.24rem;
        padding-top: 0.05rem;
        text-align: center;
        word-wrap: break-word;
      }

      .iconfont {
        font-size: 0.46rem;
        color: #c7cdd2;
      }
    }

    .active {
      p {
        color: #fff;
      }

      .iconfont {
        color: #fff;
      }
    }
  }
}

/* @import url(); 引入css类 */
</style>